import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AclService } from '@acl/service/acl.service';

export const isRootRouteAvailableCanActivateFn: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const acl = inject(AclService);
  const path = route.url[0]?.path;

  return acl.isAllowedRootRoute(path);
};

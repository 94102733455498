import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AclService } from '@acl/service/acl.service';

export const defaultRouteResolverCanActivateFn: CanActivateFn = () => {
  const acl = inject(AclService);
  const router = inject(Router);

  return router.createUrlTree([acl.getDefaultRootRoute()]);
};
